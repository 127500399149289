enum WorksiteClosureReportStatus {
    DRAFT = 'draft',
    SENT = 'sent',
    COMPLETED = 'completed',
    RESERVES = 'reserves',
    CLOSED = 'closed',
    COMPLETED_AWAITING_TENANT_SIGNATURE = 'completed_awaiting_tenant_signature',
    RESERVES_RAISED_AWAITING_TENANT_SIGNATURE = 'reserves_raised_awaiting_tenant_signature',
    RESERVES_RAISED_AWAITING_CLIENT_SIGNATURE = 'reserves_raised_awaiting_client_signature',
    RESERVES_RAISED_SIGNED_BY_TENANT = 'reserves_raised_tenant_signature',
    RESERVES_REMOVED_AWAITING_CLIENT_SIGNATURE = 'reserves_removed_awaiting_client_signature',
}

export default WorksiteClosureReportStatus;
